import dayjs from "dayjs";
import TabelaProdap from "features/shared/components/TabelaProdap";
import React from "react";
import PendencyListHeader from "./components/pendencyListHeader";
import { withStyles } from "@material-ui/core";
import { formatDateHour } from "shared/utils";
import { utc } from "dayjs";
import SynchronizationTimeCell from "./components/syncrhonizationTimeCell";

dayjs.extend(utc);

const PendencyListViewPage = ({ classes, serviceSourceTable, history }) => {
  const goToDetails = (pendency) => {
    const movementGrouperPendency = pendency.movementGrouper;
    return history.push(`/movimentacoesPendentes/detalhes/${movementGrouperPendency}`);
  };

  return (
    <div className={classes.pageContent}>
      <PendencyListHeader />
      <TabelaProdap
        servico={serviceSourceTable}
        colunas={columns(classes)}
        tabelaEsquerda={true}
        aoClicarNaLinha={goToDetails}
      />
    </div>
  );
};

const columns = (classes) => [
  {
    id: "user",
    nome: "user",
    titulo: "Usuário",
    ordenavel: false,
    cellClass: classes.cellClass,
    pegaValor: (row) => row.creationUser,
  },
  {
    id: "movementType",
    nome: "movementType",
    titulo: "Tipo de operação",
    ordenavel: false,
    cellClass: classes.cellClass,
    pegaValor: (row) => row.movementType,
  },
  {
    id: "number",
    nome: "number",
    titulo: "Total Movimentado",
    ordenavel: false,
    cellClass: classes.cellClass,
    pegaValor: (row) => `${row.number} CAB`,
  },
  {
    id: "destiny.category",
    nome: "destiny.category",
    titulo: "Categoria Final",
    ordenavel: false,
    cellClass: classes.cellClass,
    pegaValor: (row) => {
      if (row.destiny && row.destiny.category) return row.destiny.category;
      return "";
    },
  },
  {
    id: "destiny.pasture",
    nome: "destiny.pasture",
    titulo: "Pasto destino",
    ordenavel: false,
    cellClass: classes.cellClass,
    pegaValor: (row) => {
      if (row.destiny && row.destiny.pasture) return row.destiny.pasture;
      return "";
    },
  },
  {
    id: "operationDate",
    nome: "operationDate",
    titulo: "Data/Hora coleta",
    ordenavel: false,
    cellClass: classes.cellClass,
    infoMessage: "Hora referente ao estado da fazenda",
    pegaValor: (row) => formatDateHour(dayjs.utc(row.operationDate)),
  },
  {
    id: "createdAt",
    nome: "createdAt",
    titulo: "Data/Hora sincronização",
    ordenavel: false,
    cellClass: classes.cellClass,
    pegaValor: (row) => <SynchronizationTimeCell row={row} classes={classes} />,
  },
];

const styles = () => ({
  pageContent: {
    width: "100%",
    minHeight: "80vh",
    overflowX: "auto",
    padding: "5px 40px",
    zIndex: -1,
    bottom: 0,
    background: "#fff",
  },
  cellClass: {
    fontSize: "14px",
    height: "56px",
  },
  tooltip: {
    fontSize: "14px",
    color: "#FFC944",
  },
  tooltipContent: {
    fontSize: "12px",
  },
});

export default withStyles(styles)(PendencyListViewPage);
