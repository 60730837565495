import React from "react";

const styles = {
  highlight: {
    fontWeight: "bold",
    color: "#307297",
  },
};

export default [
  {
    id: "PERMITIR_ADMINISTRADOR_CRIAR_USUARIO",
    message: (
      <>
        <h3 style={{ ...styles.highlight, textAlign: "center" }}>
          Cadastro de usuários direto na plataforma!
        </h3>
        <p>
          Estamos animados em anunciar uma nova funcionalidade no FarmTell
          Views: agora, administradores{" "}
          <span style={styles.highlight}>
            podem criar novos usuários diretamente na plataforma!
          </span>
        </p>
        <p>
          Se você é um administrador, entre em contato com nosso Suporte para
          obter a liberação necessária e começar a utilizar essa melhoria.
        </p>
      </>
    ),
    minDate: "2024-07-02",
    maxDate: "2024-08-02",
    repeat: "no-repeat",
    title: "Temos novidades!",
  },
  {
    id: "LIBERACAO_PENDENCIAS",
    message: (
      <>
        <h3 style={{ ...styles.highlight, textAlign: "center" }}>
          Aviso de Pendencias de Movimentação no Portal
        </h3>
        <p>
          Temos o prazer de anunciar uma melhoria significativa no{" "}
          <span style={styles.highlight}>FarmTell Views</span> que irá facilitar
          ainda mais a gestão do seu rebanho. A partir de agora, assim como no
          Aplicativo, você será notificado diretamente no Portal Web sempre que
          houver <span style={styles.highlight}>inconsistências</span> em alguma
          informação lançada relacionada ao{" "}
          <span style={styles.highlight}>rebanho</span>, como em movimentações,
          mortes, trocas de categoria, lançamentos de pesos, entreveros ou
          saídas.
        </p>

        <p>
          Estamos constantemente trabalhando para melhorar nossas soluções e
          ajudar você a alcançar melhores resultados.
        </p>

        <p>
          Caso tenha dúvidas ou precise de suporte, nossa equipe está à
          disposição para auxiliá-lo.
        </p>
      </>
    ),
    minDate: "2024-07-08",
    maxDate: "2024-07-20",
    repeat: "no-repeat",
    title: "Temos novidades!",
  },
  {
    id: "LIBERACAO_CENTRAL_AJUDA",
    message: (
      <>
        <h3 style={{ ...styles.highlight, textAlign: "center" }}>
          Descubra a nossa Central de Ajuda!
        </h3>
        <p>
          A Central de Ajuda foi criada especialmente para você, fornecendo um
          apoio acessível, rápido e eficiente para que possa conhecer o nosso
          sistema e suas funcionalidades.
        </p>

        <p>
          Queremos que você tenha{" "}
          <span style={styles.highlight}>
            todas as informações ao seu alcance 24 horas por dia, 7 dias por
            semana!
          </span>{" "}
          Para acessar clique em{" "}
          <span style={styles.highlight}>Central de Ajuda</span> no cabeçalho da
          página!
        </p>

        <p>
          Explore essa novidade e otimize sua gestão de pecuária como nunca
          antes!
        </p>
      </>
    ),
    minDate: "2024-03-06",
    maxDate: "2024-04-06",
    repeat: "no-repeat",
    title: "Temos novidades!",
  },
  {
    id: "NOVA_VISUALIZACAO_ALTURA_DE_FORRAGEM",
    message: (
      <>
        <h3 style={{ ...styles.highlight, textAlign: "center" }}>
          Altura de Forragem!
        </h3>
        <p>
          Agora, junto com a coleta de altura de forragem no aplicativo, temos
          uma funcionalidade ainda mais poderosa à disposição!
        </p>

        <p>
          Você pode agora visualizar a localização exata de onde a coleta foi{" "}
          <span style={styles.highlight}>finalizada</span>, diretamente no nosso
          portal web, através do farol de Altura de Forragem. Isso proporciona
          uma análise ainda mais precisa e detalhada do seu manejo de pastagem.
        </p>

        <p>
          Explore essa novidade e otimize sua gestão de pecuária como nunca
          antes!
        </p>
      </>
    ),
    minDate: "2024-02-26",
    maxDate: "2024-03-26",
    repeat: "no-repeat",
    title: "Temos novidades!",
  },
  {
    id: "MANUTENCAO_PROGRAMADA_REBANHO_2",
    message: (
      <>
        <p>
          {" "}
          Gostaríamos de informar a todos os usuários que, no{" "}
          <span style={styles.highlight}>
            dia 05 de dezembro, a partir das 20:00h (horário de Brasília),
          </span>{" "}
          realizaremos uma manutenção programada em nosso sistema. Durante este
          período,{" "}
          <span style={styles.highlight}>
            o sistema ficará indisponível, não sendo possível utilizar o portal
            ou realizar sincronizações no aplicativo.
          </span>{" "}
        </p>
        <p>
          A previsão é que a manutenção se estenda{" "}
          <span style={styles.highlight}>
            até as 04:00h de amanhã, dia 06 de dezembro.
          </span>{" "}
          Recomendamos que planejem suas atividades no sistema considerando este
          intervalo de manutenção.{" "}
        </p>
        <p>
          Pedimos desculpas por qualquer inconveniente e agradecemos a sua
          paciência e compreensão. Estamos trabalhando para melhorar e sua
          experiência conosco.
        </p>
      </>
    ),
    minDate: "2023-12-04",
    maxDate: "2023-12-05",
    repeat: "no-repeat",
    title: "Aviso importante!",
    image: false,
  },
  {
    id: "MANUTENCAO_PROGRAMADA_REBANHO_3",
    message: (
      <>
        <p>
          {" "}
          Informamos a todos os usuários que,{" "}
          <span style={styles.highlight}>
            hoje, dia 05 de dezembro, a partir das 20:00h (horário de Brasília),
          </span>{" "}
          realizaremos uma manutenção programada em nosso sistema. Durante este
          período,{" "}
          <span style={styles.highlight}>
            o sistema ficará indisponível, não sendo possível utilizar o portal
            ou realizar sincronizações no aplicativo.
          </span>{" "}
        </p>
        <p>
          A previsão é que a manutenção se estenda{" "}
          <span style={styles.highlight}>
            até as 04:00h de amanhã, dia 06 de dezembro.
          </span>{" "}
          Recomendamos que planejem suas atividades no sistema considerando este
          intervalo de manutenção.{" "}
        </p>
        <p>
          Pedimos desculpas por qualquer inconveniente e agradecemos a sua
          paciência e compreensão. Estamos trabalhando para melhorar e sua
          experiência conosco.
        </p>
      </>
    ),
    minDate: "2023-12-05",
    maxDate: "2023-12-06",
    repeat: "no-repeat",
    title: "Aviso importante!",
    image: false,
  },
  {
    id: "ALTERACAO_DE_MARCA",
    message: (
      <>
        <h3 style={{ ...styles.highlight, textAlign: "center" }}>
          Mudança de marca!
        </h3>
        <p style={{ lineHeight: 1.5 }}>
          Estamos empolgados em anunciar que a plataforma está passando por
          algumas <strong>transformações</strong>, conforme detalhado no{" "}
          <strong>comunicado enviado por e-mail!</strong> Estamos apresentando
          uma nova identidade de marca e, a partir de agora, o{" "}
          <strong>Prodap Views Master</strong> passará oficialmente a se chamar
          de <span style={styles.highlight}>FarmTell Views</span>.
        </p>
      </>
    ),
    minDate: "2023-10-22",
    maxDate: "2023-10-27",
    repeat: "no-repeat",
    title: "Temos novidades!",
    image: true,
  },
  {
    id: "MANUTENCAO_PROGRAMADA_REBANHO",
    message: (
      <>
        <p>
          {" "}
          Informamos a todos os usuários que,{" "}
          <span style={styles.highlight}>
            hoje, dia 17 de outubro, a partir das 20:00h (horário de Brasília),
          </span>{" "}
          realizaremos uma manutenção programada em nosso sistema. Durante este
          período,{" "}
          <span style={styles.highlight}>
            o sistema ficará indisponível, não sendo possível utilizar o portal
            ou realizar sincronizações no aplicativo.
          </span>{" "}
        </p>
        <p>
          A previsão é que a manutenção se estenda{" "}
          <span style={styles.highlight}>
            até as 04:00h de amanhã, dia 18 de outubro.
          </span>{" "}
          Recomendamos que planejem suas atividades no sistema considerando este
          intervalo de manutenção.{" "}
        </p>
        <p>
          Pedimos desculpas por qualquer inconveniente e agradecemos a sua
          paciência e compreensão. Estamos trabalhando para melhorar e sua
          experiência conosco.
        </p>
      </>
    ),
    minDate: "2023-10-15",
    maxDate: "2023-10-18",
    repeat: "no-repeat",
    title: "Aviso importante!",
    image: false,
  },
  {
    id: "EPICO_SUPLEMENTACAO",
    message:
      "Agora você pode visualizar, editar e excluir os dados de todas as coletas de suplementação realizadas pelo aplicativo FarmTell Views. Para conferir essa novidade, clique no botão abaixo para ser direcionado para a tela.",
    link: "/coletas/suplementacao",
    minDate: "2022-09-21",
    maxDate: "2022-10-22",
    repeat: "no-repeat",
  },
  {
    id: "BLOQUEIO_COLETAS_DATA_HORA",
    message: (
      <>
        <p>
          {" "}
          A partir de hoje,{" "}
          <span style={styles.highlight}>não será mais permitido</span> realizar
          a sincronização de coletas pendentes de envio, no{" "}
          <span style={styles.highlight}>aplicativo “FarmTell Views”,</span>{" "}
          caso o fuso horário ou data/hora do celular, esteja{" "}
          <span style={styles.highlight}>diferente</span> do fuso cadastrado
          para a fazenda.
        </p>
        <p>
          Será efetuado um bloqueio durante a tentativa de sincronização. Sendo
          necessário <span style={styles.highlight}>ajustar</span> o fuso
          horário ou data/hora do celular para ficar conforme o cadastrado.{" "}
          <span style={styles.highlight}>
            Após o ajuste será possível realizar a sincronização normalmente.
          </span>
        </p>
      </>
    ),
    minDate: "2022-10-21",
    maxDate: "2022-12-24",
    repeat: "no-repeat",
  },
  {
    id: "NOVO_CADASTRO_FAZENDA",
    message: (
      <>
        Agora não será mais possível criar uma fazenda através da plataforma{" "}
        <span style={styles.highlight}>FarmTell Views</span>. As fazendas
        passarão a ser criadas por nosso time de suporte/implantação. Após ser
        criada, por nosso time, você só precisará selecionar a nova fazenda,
        informar a localização e desenhar os pastos e retiros. A troca de
        fazenda continua no mesmo lugar.
      </>
    ),
    minDate: "2023-02-13",
    maxDate: "2023-03-20",
    repeat: "no-repeat",
  },
];
