import { Grid, List, Typography, withStyles } from "@material-ui/core";
import * as fazendaActions from "features/manejo/redux/actions/fazendaActions";
import * as loteActions from "features/manejo/redux/actions/loteActions";
import * as pastoActions from "features/manejo/redux/actions/pastoActions";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { idsFarois } from "../../../../../constants/idsFarois";
import {
  PASTOS_SEM_CADASTRO,
  TODOS_OS_PASTOS,
} from "../../../../../constants/retiro";
import * as actions from "../../../../../redux/actions";
import { enviaDadosGA } from "../../../../../shared/utils";
import styles from "../../../../../styles/MenuLateral/Farol";
import AlbumFotoMorteContainer from "../../../../albumFotos/fotosMorte/view/albumFotoMorteContainer";
import { getFazendaSelecionada } from "../../../../shared/utils";
import DetalhesForragem from "./DetalhesForragem";
import DetalhesRebanho from "./DetalhesRebanho/DetalhesRebanho";
import {
  deveExibirAlbumFotoMorte,
  deveExibirAvisoEntrevero,
  deveExibirBotaoDetalhesForragem,
  deveExibirDetalhesForragem,
  deveExibirDetalhesRebanho,
  deveExibirDropDownColeta,
  deveExibirFiltroPeriodo,
  deveExibirSelectFarois,
  getDadosFarolEnviarGA,
  retornaLabelNascimentoMorte,
  verificaToggleNascimentoMorte,
} from "./FarolUtils";
import BotaoExibirDetalhesFarol from "./btnExibirDetalhesFarol";
import FaroisList from "./faroisList";
import FiltroPeriodoColeta from "./views/FiltroPeriodoColeta";
import CabecalhoFarol from "./views/cabecalhoFarol";
import DropdownSelecaoTipoColeta from "./views/dropdownSelecaoTipoColeta";

export function Farol({ ...props }) {
  const {
    classes,
    CiclosForragemFazenda,
    exibirDetalhes,
    FazendaSelecionada,
    isFazendaCreep,
    TipoFarol,
    farolSelecionado,
    farois,
    Toggles,
    TipoIconeExibido,
    PeriodoColeta,
    Pastos,
    RetiroSelecionado,
    Currais,
    pastoSelecionado,
    verificaSeExisteCochoCreep,
    fazenda,
    isClienteLite,
    lotesComEntrevero,
  } = props;

  const [exibirFiltro, setExibirFiltro] = useState(false);

  useEffect(() => {
    props.deselecionarPasto();
  }, []);

  useEffect(() => {
    verificaSeExisteCochoCreep(FazendaSelecionada);
  }, [FazendaSelecionada]);

  const limparTudo = () => {
    props.setFarolSelecionado(null);
  };

  const mudarFarol = (farol) => {
    const { buscaLotesEntrevero } = props;
    if (farol === "rebanho") {
      buscaLotesEntrevero();
    }
    props.setTipoFarol(farol);
    props.visualizarPontosColetas(false);
    props.setFarolSelecionado(null);
    props.listaFarol(false);
    setExibirFiltro(true);
    props.obterForragensFazenda(FazendaSelecionada);
  };

  const handleOpen = () => {
    const dadosEnviarGA = getDadosFarolEnviarGA(farolSelecionado);
    const { acao, rotulo, categoria, value } = dadosEnviarGA;
    enviaDadosGA(acao, rotulo, categoria, value);
    props.setExibirDetalhes(true);
  };

  const handleClose = () => {
    props.setExibirDetalhes(false);
  };

  const handleCloseAlbumFoto = () => {
    props.deselecionarPasto();
  };

  const obterTotalNascimentoMorte = () => {
    const totalNascimentoMorte = {
      totalMorte: 0,
      totalNascimento: 0,
    };

    if ([idsFarois.mortes, idsFarois.nascimentos].includes(farolSelecionado)) {
      let pastosFiltrados = [];

      if (RetiroSelecionado === TODOS_OS_PASTOS) {
        pastosFiltrados = Pastos;
      } else if (RetiroSelecionado === PASTOS_SEM_CADASTRO) {
        pastosFiltrados = Pastos.filter((pasto) => {
          if (!pasto.IdRetiro) return pasto;
          return null;
        });
      } else {
        pastosFiltrados = Pastos.filter((pasto) => {
          if (pasto.IdRetiro === RetiroSelecionado) return pasto;
          return null;
        });
      }

      const farolInvertido = [...farois].reverse();

      const morte = farolInvertido.find(
        (farol) => farol.IdFarol === idsFarois.mortes
      );
      const nascimento = farolInvertido.find(
        (farol) => farol.IdFarol === idsFarois.nascimentos
      );

      //soma as mortes e adiciona no novo objeto

      if (morte && morte.Pasto && morte.Pasto.length > 0) {
        morte.Pasto.forEach((element) => {
          if (
            pastosFiltrados.find((pasto) => pasto.IdPasto === element.IdPasto)
          ) {
            totalNascimentoMorte.totalMorte +=
              (element.Valor || 0).Quantidade || 0;
          }
        });
      }

      if (nascimento && nascimento.Pasto && nascimento.Pasto.length > 0) {
        nascimento.Pasto.forEach((element) => {
          if (
            pastosFiltrados.find((pasto) => pasto.IdPasto === element.IdPasto)
          ) {
            totalNascimentoMorte.totalNascimento +=
              (element.Valor || 0).Quantidade || 0;
          }
        });
      }
    }
    return totalNascimentoMorte;
  };

  const totalNascimentoMorte = obterTotalNascimentoMorte();

  const farol = farois[0];
  const exibirAlbumFotoMorte = deveExibirAlbumFotoMorte(
    TipoFarol,
    pastoSelecionado
  );
  const exibirDropDownColeta =
    deveExibirDropDownColeta(exibirDetalhes, FazendaSelecionada) &&
    exibirAlbumFotoMorte === false;
  const exibirFiltroPeriodo =
    deveExibirFiltroPeriodo(exibirDetalhes, exibirFiltro, TipoFarol) &&
    exibirAlbumFotoMorte === false;
  const exibirSelectFarois =
    deveExibirSelectFarois(exibirFiltro, TipoFarol, exibirDetalhes) &&
    exibirAlbumFotoMorte === false;
  const exibirDetalhesForragem = deveExibirDetalhesForragem(
    exibirDetalhes,
    TipoFarol
  );
  const exibirDetalhesRebanho = deveExibirDetalhesRebanho(
    exibirDetalhes,
    TipoFarol,
    farolSelecionado
  );
  const exibirBotaoDetalhesForragem = deveExibirBotaoDetalhesForragem(
    exibirDetalhes,
    CiclosForragemFazenda,
    exibirFiltro,
    TipoFarol
  );
  const exibirAvisoEntrevero = deveExibirAvisoEntrevero(
    lotesComEntrevero,
    TipoFarol
  );
  return (
    <Grid container>
      <Grid item xs={12}>
        <CabecalhoFarol
          pastoSelecionado={pastoSelecionado}
          exibirAlbumFotoMorte={exibirAlbumFotoMorte}
          exibirDetalhes={exibirDetalhes}
          handleClose={handleClose}
          handleCloseAlbumFoto={handleCloseAlbumFoto}
          limparTudo={limparTudo}
        />
      </Grid>
      {exibirAvisoEntrevero && (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <div className={classes.containerAvisoEntrevero}>
            <div
              style={{
                display: "flex",
                width: "99%",
                justifyContent: "center",
              }}
            >
              <img
                style={{ marginRight: "5px" }}
                src="../images/ic_warning.svg"
                alt="icone de alerta"
              />
              <Typography
                style={{ fontWeight: "bold" }}
                className={classes.avisoEntrevero}
              >
                Atenção você possui entreveros não resolvidos.
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <Typography className={classes.avisoEntrevero}>
                Acesse a tela de conciliação para saber mais detalhes.
              </Typography>
            </div>
          </div>
        </div>
      )}
      <Grid item xs={12}>
        <List>
          {exibirDropDownColeta ? (
            <DropdownSelecaoTipoColeta
              toggles={Toggles}
              tipoFarol={TipoFarol}
              mudarFarol={mudarFarol}
              verificaToggleNascimentoMorte={verificaToggleNascimentoMorte}
              retornaLabelNascimentoMorte={retornaLabelNascimentoMorte}
              isFazendaCreep={isFazendaCreep}
              isClienteLite={isClienteLite}
            />
          ) : null}
          {exibirFiltroPeriodo ? (
            <FiltroPeriodoColeta periodoColeta={PeriodoColeta} />
          ) : null}
          {!exibirSelectFarois ? null : (
            <FaroisList
              farois={farois}
              toggles={Toggles}
              farolSelecionado={farolSelecionado}
              tipoFarol={TipoFarol}
              tipoIconeExibido={TipoIconeExibido}
              abrirDetalhes={handleOpen}
              fazendaSelecionada={props.FazendaSelecionada}
              totalNascimentoMorte={totalNascimentoMorte}
            />
          )}

          {exibirDetalhesForragem ? (
            <DetalhesForragem
              classes={classes}
              handleClose={handleClose}
              CicloForragemFazenda={CiclosForragemFazenda}
            />
          ) : null}

          {exibirDetalhesRebanho ? (
            <DetalhesRebanho
              nomeFazenda={fazenda.Nome}
              handleClose={handleClose}
              pastos={Pastos}
              retiroSelecionado={RetiroSelecionado}
              farolPasto={farol.Pasto}
              categorias={farol.Categorias}
              currais={Currais}
            />
          ) : null}

          <BotaoExibirDetalhesFarol
            exibirBotao={exibirBotaoDetalhesForragem}
            abrirDetalhes={handleOpen}
          />
          {exibirAlbumFotoMorte ? <AlbumFotoMorteContainer /> : null}
        </List>
      </Grid>
    </Grid>
  );
}

Farol.propTypes = {
  farois: PropTypes.array.isRequired,
  exibirDetalhes: PropTypes.bool,
  farolSelecionado: PropTypes.number,
  FazendaSelecionada: PropTypes.number,
  TipoFarol: PropTypes.string,
  CiclosForragemFazenda: PropTypes.array,
  TipoIconeExibido: PropTypes.string,
  pastoSelecionado: PropTypes.object,
};

const mapDispatchToProps = {
  listaFarol: actions.listaFarol,
  setFarolSelecionado: actions.setFarolSelecionado,
  setTipoFarol: actions.setTipoFarol,
  setExibirDetalhes: actions.setExibirDetalhes,
  obterForragensFazenda: actions.obterForragensFazenda,
  deselecionarPasto: pastoActions.deselecionarPasto,
  verificaSeExisteCochoCreep: fazendaActions.verificaSeExisteCochoCreep,
  buscaLotesEntrevero: loteActions.buscaLotesEntrevero,
  visualizarPontosColetas: actions.visualizarPontosColetas,
};

const mapStateToProps = (state) => {
  return {
    fazenda: getFazendaSelecionada(
      state.fazenda.FazendaSelecionada,
      state.fazenda.Fazendas
    ),
    lotesComEntrevero: state.lote.lotesComEntrevero,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Farol);
