import React from 'react';
import { AdvancedMarker } from "components/ViewsGoogleMaps/AdvancedMarker";

export const IconeFarol = props => {
  const { cor, valorExibido, posicaoIcone, handleSetPastoComColetaMorte } = props;
  const label = obtemLabelIcone(valorExibido.toString(), cor);
  return renderIcone(posicaoIcone, label, handleSetPastoComColetaMorte);
};

const renderIcone = (position, label, handleSetPastoComColetaMorte) => {
  return (
    <AdvancedMarker position={position} key={position.lat} onClick={handleSetPastoComColetaMorte}>
      <div style={{
        backgroundColor: "#fff",
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        color: label.color,
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>{label.text}</div>
    </AdvancedMarker>);
};
const obtemLabelIcone = (textoIcone, cor) => {
  return { text: textoIcone, color: cor, fontSize: '12px', fontWeight: 'bold' };
};

export default IconeFarol;
