import React from "react";
import {
  ListItem,
  Grid,
  List,
  Typography,
  Divider,
  withStyles,
} from "@material-ui/core";
import {
  deveExibirBotaoDetalhesRebanho,
  trataFaroisRenderizar,
} from "./FarolUtils";
import FarolItem from "./farolItem";
import { idsFarois } from "../../../../../constants/idsFarois";

export const faroisList = (props) => {
  const retornaListaFarois = () => {
    const {
      farois,
      toggles,
      farolSelecionado,
      tipoFarol,
      abrirDetalhes,
      tipoIconeExibido,
      dataAtualizacao,
      fazendaSelecionada,
    } = props;
    const farolRebanho = deveExibirBotaoDetalhesRebanho(tipoFarol);
    const faroisListar = trataFaroisRenderizar(farois, toggles);
    return faroisListar.map((farol) => {
      const exibirBotao =
        farolRebanho &&
        farol.IdFarol === farolSelecionado &&
        farol.IdFarol !== idsFarois.ganhoPeso;
      return (
        <FarolItem
          key={farol.IdFarol}
          farol={farol}
          farolSelecionado={farolSelecionado}
          tipoIconeExibido={tipoIconeExibido}
          abrirDetalhes={abrirDetalhes}
          dataAtualizacao={dataAtualizacao}
          exibirBotao={exibirBotao}
          fazendaSelecionada={fazendaSelecionada}
          toggles={toggles}
        />
      );
    });
  };

  const retornaTotalNascimentoMorte = () => {
    const { totalNascimentoMorte, classes, farolSelecionado } = props;
    return (
      <>
        {farolSelecionado === idsFarois.mortes ||
        farolSelecionado === idsFarois.nascimentos ? (
          <>
            <Grid item xs={12}>
              <Typography align="left" variant="subtitle1" gutterBottom>
                Total:
              </Typography>
            </Grid>
            <div className={classes.containerInfoNascimentoMorte}>
              <Typography
                className={classes.textoNascimentoMorte}
                align="left"
                variant="subtitle1"
                gutterBottom
              >
                Nascimentos :{" "}
                <span className={classes.valorTextoNascimentoMorte}>
                  {" "}
                  {totalNascimentoMorte.totalNascimento}{" "}
                </span>
              </Typography>
              <Typography
                className={classes.textoNascimentoMorte}
                align="left"
                variant="subtitle1"
                gutterBottom
              >
                Mortes :{" "}
                <span className={classes.valorTextoNascimentoMorte}>
                  {" "}
                  {totalNascimentoMorte.totalMorte}
                </span>
              </Typography>
            </div>
          </>
        ) : null}
      </>
    );
  };

  return (
    <React.Fragment>
      <ListItem key="SelectFaroisListItem">
        <Grid container>
          {retornaTotalNascimentoMorte()}
          <Grid item xs={12}>
            <Typography align="left" variant="subtitle1" gutterBottom>
              Faróis:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <List>{retornaListaFarois()}</List>
          </Grid>
        </Grid>
      </ListItem>
      <Divider key="DividerSelectFaroisListItem" variant="middle" />
    </React.Fragment>
  );
};

const styles = (theme) => ({
  containerInfoNascimentoMorte: {
    display: "flex",
    flexDirection: "row",
    gridGap: "41px",
    paddingBottom: "16px",
  },
  textoNascimentoMorte: {
    height: "19px",
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: "16px",
    color: theme.palette.primary.main,
  },
  valorTextoNascimentoMorte: {
    fontWeight: "normal",
    color: "#212121",
  },
});
export default withStyles(styles)(faroisList);
