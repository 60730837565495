import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Input from "./components/Input";
import Option from "./components/Option";

const DEFAULT_STYLES = {
  width: "100%",
  backgroundColor: "#ffffff",
};
const DEFAULT_STRINGS = {
  noOptions: "Não existem opções disponíveis.",
};

function SelectDropdown({
  id,
  testId,
  isMulti,
  isLoading,
  isSearchable,
  isDisabled,
  isClearable,
  options = [],
  tooltipMessage,
  value,
  label,
  groupBy,
  error,
  helperText,
  emptyText,
  overrideStrings = DEFAULT_STRINGS,
  styles,
  renderOptionComponent,
  onChange,
  onPaginate,
  onSearch,
  onPagineThresholdInPorcentage = 0.9,
  size = "medium",
}) {
  const [_options, _setOptions] = useState(options);
  const handleChange = (_, newValue) => {
    if (!newValue) return onChange(null);
    if (isMulti) {
      return onChange(newValue.map((item) => item.value || item));
    }
    return onChange(newValue.value);
  };

  const handleInputChange = (event) => {
    if (!event) return;
    onSearch
      ? onSearch(event.target.value || "")
      : handleSearch(event.target.value || "");
  };

  const onScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    const currentScroll = scrollTop + clientHeight;
    const scrollThreshold = scrollHeight * onPagineThresholdInPorcentage;

    if (currentScroll >= scrollThreshold) {
      onPaginate && onPaginate();
    }
  };

  const getOptionDisabled = (option) => option.disabled;

  const getOptionLabel = (option) => {
    if (option.label) return option.label;
    const optionSelected = (options || []).find((o) => o.value === option);
    return optionSelected ? optionSelected.label : option;
  };

  const isOptionEqualToValue = (option, value) => {
    if (!value) return false;
    return String(option.value) === String(value.value || value);
  };

  const handleSearch = (searchText) => {
    const processOptions = options.map((option) => {
      if (
        option.label &&
        option.label.toLowerCase().includes(searchText.toLowerCase())
      ) {
        return option;
      }
      return {
        ...option,
        visible: false,
      };
    });
    _setOptions(processOptions);
  };

  const renderOption = (props, option, { selected }) =>
    option.visible === false ? null : (
      <Option
        key={option.value}
        isMulti={isMulti}
        props={props}
        option={option}
        selected={selected}
      />
    );
  const renderInput = (params) => (
    <Input
      {...params}
      emptyText={emptyText}
      label={label}
      tooltipMessage={tooltipMessage}
      isLoading={isLoading}
      isSearchable={isSearchable}
      error={error}
      helperText={helperText}
    />
  );

  useEffect(() => {
    _setOptions(options);
  }, [options]);

  return (
    <Autocomplete
      id={id}
      sx={{ ...DEFAULT_STYLES, ...styles }}
      data-testid={testId}
      disablePortal={false}
      disabled={isDisabled}
      loading={isLoading}
      multiple={isMulti}
      labelid={label && label.id}
      options={_options}
      value={value}
      groupBy={groupBy}
      getOptionDisabled={getOptionDisabled}
      disableClearable={!isClearable}
      disableCloseOnSelect={isMulti}
      blurOnSelect={!isMulti}
      onChange={handleChange}
      onInputChange={handleInputChange}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      selectOnFocus={false}
      openOnFocus={true}
      noOptionsText={overrideStrings.noOptions}
      ListboxProps={{
        onScroll,
      }}
      renderInput={renderInput}
      renderOption={renderOptionComponent || renderOption}
      size={size}
    />
  );
}

export default SelectDropdown;
