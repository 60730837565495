import React, { Component } from "react";
import { Typography, Grid, withStyles } from "@material-ui/core";
import Carousel from "nuka-carousel";
import Icon from "@material-ui/core/Icon";
import ModalFoto from "./modalFoto";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

export class ItemFotoMorte extends Component {
  constructor(props) {
    super(props);
    this.getFotoIndex = this.getFotoIndex.bind(this);
    const { idColetaFoto } = this.props;
    this.state = {
      urlAnimal: `${process.env.REACT_APP_ENDPOINT_BUCKET}/MORTE/${idColetaFoto}/imgCARCACA.png`,
      urlBrinco: `${process.env.REACT_APP_ENDPOINT_BUCKET}/MORTE/${idColetaFoto}/imgBRINCO.png`,
      urlAnimalExiste: true,
      urlBrincoExiste: true,
      modal: false,
      slideIndex: 0,
      idColetaFotoAtivo: 0,
      botaoSlideAnteriorVisivel: false,
      botaoProximoSlideVisivel: true,
    };
  }

  toggleModal(index, idColetaFoto) {
    this.setState({
      modal: !this.state.modal,
      slideIndex: index,
      idColetaFotoAtivo: idColetaFoto,
    });
  }

  renderCenterLeftControls = ({ previousSlide }) =>
    this.state.botaoSlideAnteriorVisivel ? (
      <Grid
        onClick={() => this.slideAnterior(previousSlide)}
        className={this.props.classes.gridBotaoCarousel}
      >
        <Icon style={{ color: "#ffffff", fontSize: "36px" }}>
          navigate_before
        </Icon>
      </Grid>
    ) : null;

  slideAnterior = (previousSlide) => {
    this.setState({
      botaoSlideAnteriorVisivel: false,
      botaoProximoSlideVisivel: true,
    });
    previousSlide();
  };

  renderCenterRightControls = ({ nextSlide }) =>
    this.state.botaoProximoSlideVisivel ? (
      <Grid
        onClick={() => this.proximoSlide(nextSlide)}
        className={this.props.classes.gridBotaoCarousel}
      >
        <Icon style={{ color: "#ffffff", fontSize: "36px" }}>
          navigate_next
        </Icon>
      </Grid>
    ) : null;

  proximoSlide = (nextSlide) => {
    this.setState({
      botaoSlideAnteriorVisivel: true,
      botaoProximoSlideVisivel: false,
    });
    nextSlide();
  };

  renderBottomCenterControls = ({ currentSlide }) => {
    const { classes } = this.props;

    return (
      <Grid style={styles.gridSvg}>
        <svg
          width={8}
          height={8}
          className={
            currentSlide === 0
              ? classes.controlCarouselSelecionado
              : classes.controlCarousel
          }
        >
          <circle cx={4} cy={4} r={4}></circle>
        </svg>
        <svg
          width={8}
          height={8}
          className={
            currentSlide === 1
              ? classes.controlCarouselSelecionado
              : classes.controlCarousel
          }
        >
          <circle cx={4} cy={4} r={4}></circle>
        </svg>
      </Grid>
    );
  };

  render() {
    const {
      coleta,
      classes,
      listaIdFotosPasto,
      idColetaFoto,
      nomePasto,
      coletasPastoSelecionado,
    } = this.props;
    const {
      urlAnimalExiste,
      urlBrincoExiste,
      modal,
      slideIndex,
      idColetaFotoAtivo,
    } = this.state;
    const dataColeta = new Date(coleta.DataColeta).toLocaleDateString();

    return (
      <Card className={classes.card}>
        <Carousel
          renderCenterLeftControls={this.renderCenterLeftControls}
          renderCenterRightControls={this.renderCenterRightControls}
          renderBottomCenterControls={this.renderBottomCenterControls}
          cellAlign="center"
          className={classes.carousel}
          id="carousel"
        >
          <Grid container justify={"center"} style={styles.gridImg}>
            <img
              id="img_animal"
              src={
                urlAnimalExiste
                  ? this.state.urlAnimal
                  : "../images/sem-imagem.svg"
              }
              alt="img_animal"
              onError={() => this.substituiFotoAnimal()}
              className={this.getStyleImgAnimal()}
              onClick={() =>
                this.toggleModal(
                  this.getFotoIndex(coleta.IdColetaFoto),
                  coleta.IdColetaFoto
                )
              }
            />
          </Grid>
          <Grid container justify={"center"} style={styles.gridImg}>
            <img
              id="img_brinco"
              src={
                urlBrincoExiste
                  ? this.state.urlBrinco
                  : "../images/sem-imagem.svg"
              }
              alt="img_brinco"
              onError={() => this.substituiFotoBrinco()}
              className={this.getStyleImgBrinco()}
              onClick={() =>
                this.toggleModal(
                  this.getFotoIndex(coleta.IdColetaFoto) + 1,
                  coleta.IdColetaFoto
                )
              }
            />
          </Grid>
        </Carousel>
        <CardContent>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              align="left"
              className={classes.infoCardCategoria}
              id="nomeCategoria"
            >
              {coleta.Categoria ? coleta.Categoria.toUpperCase() : ""}
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.gridInfoCard}>
            <Typography
              variant="caption"
              align="left"
              className={classes.labelInfoCard}
            >
              {"Causa: "}
            </Typography>
            <Typography variant="caption" align="left" id="causa">
              {coleta.Causa}
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.gridInfoCard}>
            <Typography
              variant="caption"
              align="left"
              className={classes.labelInfoCard}
            >
              {"Código: "}
            </Typography>
            <Typography variant="caption" align="left" id="codigo">
              {coleta.Identificador ? coleta.Identificador : " - "}
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.gridInfoCard}>
            <Typography
              variant="caption"
              align="left"
              className={classes.labelInfoCard}
            >
              {"Coleta: "}
            </Typography>
            <Typography variant="caption" align="left" id="dataColeta">
              {dataColeta}
            </Typography>
          </Grid>
          {this.state.modal && (
            <ModalFoto
              id="modal"
              idColetaFoto={idColetaFoto}
              nomePasto={nomePasto}
              coleta={coleta}
              coletasPastoSelecionado={coletasPastoSelecionado}
              listaIdFotosPasto={listaIdFotosPasto}
              modal={modal}
              toggleModal={() => this.toggleModal()}
              slideIndex={slideIndex}
              idColetaFotoAtivo={idColetaFotoAtivo}
              getFotoIndex={this.getFotoIndex}
            ></ModalFoto>
          )}
        </CardContent>
      </Card>
    );
  }

  getFotoIndex(idColetaFoto) {
    const { listaIdFotosPasto } = this.props;
    if (listaIdFotosPasto.find((foto) => foto === idColetaFoto))
      return listaIdFotosPasto.indexOf(idColetaFoto) * 2;
    else return 0;
  }

  substituiFotoAnimal() {
    this.setState({ urlAnimalExiste: false });
  }

  substituiFotoBrinco() {
    this.setState({ urlBrincoExiste: false });
  }

  getStyleImgAnimal() {
    const { classes } = this.props;
    const { urlAnimalExiste } = this.state;
    return urlAnimalExiste ? classes.imagemCard : classes.semImagem;
  }

  getStyleImgBrinco() {
    const { classes } = this.props;
    const { urlBrincoExiste } = this.state;
    return urlBrincoExiste ? classes.imagemCard : classes.semImagem;
  }
}

const styles = {
  card: {
    width: "90%",
    height: "100%",
  },
  carousel: {
    height: "60% !important",
    width: "100%",
  },
  imagemCard: {
    width: "100%",
    cursor: "default",
  },
  labelInfoCard: {
    width: "60px",
  },
  gridInfoCard: {
    display: "flex",
  },
  infoCardCategoria: {
    color: "#424242",
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "10px",
  },
  gridImg: {
    height: "200px",
  },
  gridBotaoCarousel: {
    flexDirection: "row",
    alignItems: "center",
    width: "45px",
    height: "45px",
    display: "flex",
    cursor: "pointer",
  },
  controlCarousel: {
    cursor: "default",
    opacity: 0.5,
    background: "transparent",
    border: "none",
    fill: "white",
    borderRadius: "10px",
    boxShadow: "rgb(8, 8, 8) 0px 0px 2px 0px",
  },
  controlCarouselSelecionado: {
    cursor: "default",
    opacity: 1,
    background: "transparent",
    border: "none",
    fill: "white",
    borderRadius: "10px",
    boxShadow: "rgb(8, 8, 8) 0px 0px 2px 0px",
  },
  semImagem: {
    width: "24px",
    height: "24px",
    opacity: 0.9,
    alignSelf: "center",
  },
  gridSvg: {
    display: "flex",
    justifyContent: "space-between",
    width: "25px",
    marginBottom: "5px",
  },
};

export default withStyles(styles)(ItemFotoMorte);
