import React, { useCallback } from "react";
import { withStyles } from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBack";
import PendencyDetailsCard from "../pendencyDetailsView/components/pendencyDetailsCard";
import MovementSituationCards from "../pendencyDetailsView/components/movementSituationCards";

const PendencyDetailsPage = ({
  classes,
  history,
  countOrigin,
  countDestiny,
  pendency,
  movementGrouper,
  originCategoryName,
  originPastureName,
  destinyCategoryName,
  destinyPastureName,
}) => {
  const onGoBack = useCallback(() => {
    return history.push("/movimentacoesPendentes");
  });
  return (
    <div className={classes.pageContent}>
      <div className={classes.goBackContainer}>
        <BackIcon className={classes.iconBack} onClick={onGoBack} />
        <h1 data-testid="TestGoBack" className={classes.goBackText}>
          Voltar
        </h1>
      </div>
      <MovementSituationCards
        data-testid="MovementSituationCards"
        classes={classes}
        countOrigin={countOrigin}
        countDestiny={countDestiny}
        movementGrouper={movementGrouper}
        originCategoryName={originCategoryName}
        originPastureName={originPastureName}
        destinyCategoryName={destinyCategoryName}
        destinyPastureName={destinyPastureName}
      />
      <PendencyDetailsCard
        data-testid="PendencyDetailsCard"
        classes={classes}
        pendency={pendency}
        countOrigin={countOrigin}
      />
    </div>
  );
};

const styles = (theme) => ({
  pageContent: {
    width: "100%",
    minHeight: "80vh",
    overflowX: "auto",
    padding: "5px 40px",
    zIndex: -1,
    bottom: 0,
    background: "#fff",
  },
  goBackContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",
    marginTop: "8px",
  },
  goBackText: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#444",
  },
  iconBack: {
    color: theme.palette.primary.main,
    marginRight: "12px",
    cursor: "pointer",
  },
});
export default withStyles(styles)(PendencyDetailsPage);
