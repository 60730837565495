import BaseService from "services/BaseService";

export const PENDENCY_URL = `${process.env.REACT_APP_ENDPOINT}herds/pendency`;
export const LIVESTOCK_URL = `${process.env.REACT_APP_ENDPOINT}herds/livestock`;

class PendencyService extends BaseService {
  async getPendenciesByFarmId(page, size, farmId, filterDate) {
    const urlPendenciesByFarmId = `${PENDENCY_URL}/byFarmId/${farmId}`;
    return this.get(urlPendenciesByFarmId, { page, size, filterDate });
  }

  async getPendenciesByMovementGrouper(movementGrouper) {
    const urlPendenciesByMovementGrouper = `${PENDENCY_URL}/byGrouper/${movementGrouper}`;
    const response = await this.get(urlPendenciesByMovementGrouper);
    return response.data.data;
  }

  async getCountAnimalCategoryInPasture(animalCategoryId, pastureId, todayDate) {
    let query = {
      animalCategoryId: animalCategoryId,
      pastureId: pastureId,
      date: todayDate,
    }
    const parametrosURL = this.montaURLParametros(query);
    const urlServico = this.montaURL(`${LIVESTOCK_URL}/count/`, parametrosURL);
    const response = await this.get(urlServico);

    return response.data;
  };
  
  async getLastPendencyByFarmId(farmId) {
    const urlLastPendencyByFarmId = `${PENDENCY_URL}/moreRecentByFarmId/${farmId}`;
    return this.get(urlLastPendencyByFarmId);
  }

}

export default new PendencyService();
