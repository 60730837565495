import ReactGA from "react-ga";
import constants from "constants/global";
import TableauReportService from "../features/analiticopdf/tableauReportService";

import _ from "lodash";
import moment from "moment";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import {
  getFazendaStorage,
  setCentralDeAjudaStorage,
  setClienteStorage,
  setFazendaSelecionadaStorage,
  setFazendaStorage,
} from "./storageUtils";

dayjs.extend(utc);


export const enviaDadosGA = (acao, rotulo, categoria, valor) => {
  ReactGA.event({
    action: acao,
    label: rotulo,
    category: categoria,
    value: valor,
  });
};

export const salvarDadosLocalStorage = (states, action) => {
  if (
    (action.type === "SET_FAZENDA" || action.type === "LISTA_FAZENDAS") &&
    states.fazenda.Fazendas.length > 0 &&
    states.fazenda.FazendaSelecionada
  ) {
    states.fazenda.Fazendas = _.filter(states.fazenda.Fazendas, {
      Ativo: true,
      Excluido: false,
    });

    setFazendaSelecionadaStorage(states.fazenda.FazendaSelecionada);
    setFazendaStorage(states.fazenda);
  }

  if (
    (action.type === "SET_CLIENTE" ||
      action.type === "LISTA_USUARIOS_CLIENTES") &&
    states.cliente.ClienteSelecionado
  ) {
    setClienteStorage(states.cliente.ClienteSelecionado.IdCliente);
    setCentralDeAjudaStorage({
      acessaCentralAjuda: states.cliente.ClienteSelecionado.AcessaCentralAjuda,
      liberadoSuporte: states.cliente.ClienteSelecionado.LiberadoSuporte,
    });
  }
};

export const checaESalvaLocalstorageProps = ({ getState }) => {
  return (next) => (action) => {
    next(action);
    salvarDadosLocalStorage(getState(), action);
  };
};
export const enviaDadosGAPorRotulo = (acao, rotulo, categoria) => {
  ReactGA.event({
    action: acao,
    label: rotulo,
    category: categoria,
  });
};

export const montarTextoMapaRebanhoGA = (idFazenda, abaSelecionada) => {
  let emailUsuario = localStorage.getItem("Email");
  let fazendaSelecionada = obterFazendaDoContexto(idFazenda);
  const inicioEmailUsuario = emailUsuario.split("@")[0];
  const finalEmailUsuario = emailUsuario.split("@")[1];

  const acao = `Acesso ${abaSelecionada}:
  inicio do email do usuário: ${inicioEmailUsuario}
  final do email do usuário: ${finalEmailUsuario}
  Fazenda: ${fazendaSelecionada.Nome}
  IdFazenda: ${idFazenda}`;
  const rotulo = abaSelecionada;
  const categoria = abaSelecionada;

  enviaDadosGAPorRotulo(acao, rotulo, categoria);
};

export const montarTextoFiltro = (idFazenda, dataInicio, dataFinal) => {
  let emailUsuario = localStorage.getItem("Email");
  let fazendaSelecionada = obterFazendaDoContexto(idFazenda);
  const inicioEmailUsuario = emailUsuario.replace("@", "_");

  const acao = `Fazenda: ${fazendaSelecionada.Nome}
  IdFazenda: ${idFazenda}
  DataInicio: ${moment(dataInicio).format("DD/MM/YYYY")}
  DataFinal: ${moment(dataFinal).format("DD/MM/YYYY")}
  `;
  const rotulo = inicioEmailUsuario;
  const categoria = "Conciliacao Filtro";

  enviaDadosGAPorRotulo(acao, rotulo, categoria);
};

export const obterFazendaDoContexto = (idFazendaSelecionada) => {
  let localFazendas = getFazendaStorage();
  localFazendas = JSON.parse(localFazendas);
  let FazendaSelecionada = localFazendas.Fazendas.find(
    (fazenda) => fazenda.IdFazenda === idFazendaSelecionada
  );
  return FazendaSelecionada;
};

export const buscaTicketTableauEmbedded = async (email) => {
  try {
    const ticket = await TableauReportService.buscaTicketTableauEmbedded(email);
    return ticket.data;
  } catch (exception) {
    console.log(`Erro ao buscar ticket tableau embedded: ${exception}`);
    return "";
  }
};

export const formatDate = (date) =>
  moment(date).format(constants.defaultDateFormat);

export const formatDateHour = (date) => dayjs(date).format(constants.defaultDateTimeFormat);

export const removeUndefinedEntries = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value || value === 0 || value === false) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === constants.orderBy.desc
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const calcPeriodMonthTitles = (initialDate, dateMonthFormat) => {
  const titles = Array.from({ length: 12 }, (_, position) => {
    const cloneDate = dayjs(initialDate);
    return cloneDate.add(position, "M").format(dateMonthFormat);
  });
  return titles;
};

export const convertStringBooleanToBoolean = (value) => {
  switch (value) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return value;
  }
};

/**
 * Incrementa a data fornecida por uma quantidade específica de uma unidade de tempo.
 *
 * @param {Date|string} date - A data de referência para a incrementação. Pode ser um objeto Date ou uma string de data válida.
 * @param {number} quantity - A quantidade a ser adicionada à data.
 * @param {string} type - O tipo de unidade de tempo para a incrementação ("day","week","month","quarter","year","hour","minute","second","millisecond").
 * @returns {Date} - A nova data após a incrementação.
 */
export const incrementDate = (date, quantity, type) => {
  try {
    // Se a data não for fornecida, usa a data atual
    const _date = date ? new Date(date) : new Date();

    // Cria um objeto Day.js com a data fornecida ou a data atual
    const dateToDayjsObj = dayjs(_date);

    // Adiciona a quantidade especificada ao tipo de unidade de tempo
    const modifiedDate = dateToDayjsObj.add(quantity, type);

    // Retorna a data resultante em formato JavaScript
    return modifiedDate.toDate();
  } catch (error) {
    console.error("Erro ao incrementar a data:", error.message);
    return date; // Retorna a data original em caso de erro
  }
};

/**
 * Verifica se um valor é uma data válida.
 *
 * @param {*} value - O valor a ser verificado.
 * @returns {boolean} - True se o valor for uma data válida, false caso contrário.
 */
export const isValidDate = (value) =>
  value instanceof Date && !isNaN(value.getTime());

/**
 * Converte uma string no formato ISO 8601 em um objeto Date.
 *
 * @param {string} dateString - A string a ser convertida.
 * @returns {Date} - O objeto Data.
 * @throws {Error} - Se a string não estiver em um formato válido.
 */

export const parseISODate = (dateString) => {
  const date = new Date(dateString);
  if (isValidDate(date)) {
    return date;
  } else {
    throw new Error("Invalid ISO date string");
  }
};

/**
 * Calcula a diferença entre duas datas e retorna os campos selecionados.
 *
 * @param {Date|string} startDate - A data de início. Pode ser um objeto Date ou uma string no formato ISO 8601.
 * @param {Date|string} endDate – A data de término. Pode ser um objeto Date ou uma string no formato ISO 8601.
 * @returns {Object} – Um objeto contendo os campos selecionados e seus valores.
 * @throws {Error} - Se startDate ou endDate não forem objetos de data válidos ou strings no formato ISO 8601.
 */

export const dateDiff = (startDate, endDate) => {
  // Converte strings em objetos Date, se necessário
  if (typeof startDate === "string") {
    startDate = parseISODate(startDate);
  }
  if (typeof endDate === "string") {
    endDate = parseISODate(endDate);
  }

  // Valida startDate e endDate
  if (!isValidDate(startDate) || !isValidDate(endDate)) {
    throw new Error("Invalid start or end date");
  }

  const millisecondsDiff = Math.abs(endDate - startDate);
  const result = {};

  // Calcular todas as unidades de uma vez
  const totalSeconds = millisecondsDiff / 1000;
  result.hours = Math.floor(totalSeconds / 3600);
  result.minutes = Math.floor((totalSeconds % 3600) / 60);
  result.seconds = Math.floor(totalSeconds % 60);
  result.milliseconds = millisecondsDiff % 1000;

  return result;
};
