import _ from "lodash";
import React, { useState } from "react";

import { PASTOS_SEM_CADASTRO, TODOS_OS_PASTOS } from "constants/retiro";
import PoligonoFarol from "./PoligonoFarol";

const PastosFarol = ({
  retiroSelecionado,
  farolSelecionado,
  pastos,
  farois,
  TipoIconeExibido,
  zoomExibeIconePastoFarol,
  forragens,
  TipoFarol,
  pastosPorCocho,
  onMouseOverPasto,
  visualizarPontosColetas,
}) => {
  const [idCochoCompartilhadoSelecionado, setIdCochoCompartilhadoSelecionado] =
    useState(null);
  const [idModuloSelecionado, setIdModuloSelecionado] = useState(null);

  const setIdCochoCompartilhado = (idCocho) => {
    if (idCocho !== idCochoCompartilhadoSelecionado) {
      setIdCochoCompartilhadoSelecionado(idCocho);
    }
  };

  const setIdModulo = (idModulo) => {
    if (idModulo !== idModuloSelecionado) {
      setIdModuloSelecionado(idModulo);
    }
  };

  return (
    <>
      {_.chain(pastos)
        .filter((pasto) => {
          if (retiroSelecionado === TODOS_OS_PASTOS) return true;
          else if (retiroSelecionado === PASTOS_SEM_CADASTRO)
            return !pasto.IdRetiro;
          else return pasto.IdRetiro === retiroSelecionado;
        })
        .map((pasto, index) => {
          let destacarModulo = false;
          let destacarCochoCompartilhado = false;

          if (!!idModuloSelecionado && !!pasto.IdModulo) {
            destacarModulo = pasto.IdModulo === idModuloSelecionado;
          }

          if (!!idCochoCompartilhadoSelecionado && !!pasto.Cocho) {
            destacarCochoCompartilhado =
              pasto.Cocho.IdCocho === idCochoCompartilhadoSelecionado;
          }

          let pastosComCochoCompartilhado = [];
          if (pasto.Cocho && pasto.Cocho.IdCocho)
            pastosComCochoCompartilhado = pastosPorCocho[pasto.Cocho.IdCocho];



          return (
            <PoligonoFarol
              key={pasto.IdPasto}
              pastosComCochoCompartilhado={pastosComCochoCompartilhado}
              pasto={pasto}
              farolPasto={pasto.farolPasto}
              TipoFarol={TipoFarol}
              TipoIconeExibido={TipoIconeExibido}
              forragens={forragens}
              zoomExibeIconePastoFarol={zoomExibeIconePastoFarol}
              idFarolSelecionado={farolSelecionado}
              categoriasLotes={pasto.categoriasLotes}
              setCochoCompartilhadoSelecionado={setIdCochoCompartilhado}
              destacarModulo={destacarModulo}
              destacarCochoCompartilhado={destacarCochoCompartilhado}
              setModuloSelecionado={setIdModulo}
              onMouseOverChange={onMouseOverPasto}
              visualizarPontosColetas={visualizarPontosColetas}
              coletaAlturaPasto={pasto.ColetaAlturaPasto}
            />
          );
        })
        .value()}
    </>
  );
};

const diffToMemoize = (prevProps, nextProps) => {

  const retiroSelecionado = prevProps.retiroSelecionado === nextProps.retiroSelecionado;
  const farolSelecionado = prevProps.farolSelecionado === nextProps.farolSelecionado;
  const pastos = prevProps.pastos === nextProps.pastos;
  const farois = prevProps.farois === nextProps.farois;
  const TipoIconeExibido = prevProps.TipoIconeExibido === nextProps.TipoIconeExibido;
  const zoomExibeIconePastoFarol = prevProps.zoomExibeIconePastoFarol === nextProps.zoomExibeIconePastoFarol;
  const forragens = prevProps.forragens === nextProps.forragens;
  const TipoFarol = prevProps.TipoFarol === nextProps.TipoFarol;
  const pastosPorCocho = prevProps.pastosPorCocho === nextProps.pastosPorCocho;

  const visualizarPontosColetas = prevProps.visualizarPontosColetas === nextProps.visualizarPontosColetas;


  return (retiroSelecionado &&
    farolSelecionado &&
    pastos &&
    farois &&
    TipoIconeExibido &&
    zoomExibeIconePastoFarol &&
    forragens &&
    TipoFarol &&
    pastosPorCocho &&

    visualizarPontosColetas);
}

export default React.memo(PastosFarol, diffToMemoize);
