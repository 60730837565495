import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { formatDateHour } from "shared/utils";
import dayjs from "dayjs";
import { utc } from "dayjs";
import CustomTextField from "features/shared/components/CustomTextField";
dayjs.extend(utc);
const CONTENT_TOOLTIP =
  "Atenção: esta operação demorou mais de 12 horas para ser sincronizada.";
const PendencyDetailsCard = ({ classes, pendency, countOrigin }) => {
  const [originRetreatName, setOriginRetreatName] = useState("");
  const [originPastureName, setOriginPastureName] = useState("");
  const [creationUserName, setCreationUserName] = useState("");
  const [typeMovement, setTypeMovement] = useState("");
  const [number, setNumber] = useState(null);
  const [originCategoryName, setOriginCategoryName] = useState("");
  const [destinyCategoryName, setDestinyCategoryName] = useState("");
  const [destinyPastureName, setDestinyPastureName] = useState("");
  const [dateOperation, setDateOperation] = useState("");
  const [creationDate, setCreationDate] = useState("");
  const [reasonPendency, setReasonPendency] = useState("");
  const [isLateSync, setIsLateSync] = useState(false);

  useEffect(() => {
    if (pendency && Object.keys(pendency).length > 0) {
      setOriginRetreatName(pendency.origin.retreat);
      setOriginPastureName(pendency.origin.pasture);
      setCreationUserName(pendency.creationUser);
      setTypeMovement(pendency.movementType);
      setNumber(pendency.number);
      setOriginCategoryName(pendency.origin.category);
      setDestinyCategoryName(pendency.destiny.category);
      setDestinyPastureName(pendency.destiny.pasture);
      setDateOperation(formatDateHour(dayjs.utc(pendency.operationDate)));
      setCreationDate(formatDateHour(dayjs.utc(pendency.createdAt)));
      setReasonPendency(pendency.pendencyReason);
    }
  }, [pendency]);

  useEffect(() => {
    if (pendency.createdAt && pendency.operationDate) {
      const createdAtConvertedTime = new Date(pendency.createdAt).getTime();
      const operationDateConvertedTime = new Date(
        pendency.operationDate
      ).getTime();
      const convertedTwelveHours = 12 * 60 * 60 * 1000;
      const isLate =
        createdAtConvertedTime >=
        operationDateConvertedTime + convertedTwelveHours;
      setIsLateSync(isLate);
    }
  }, [pendency]);

  const splitReasonPendency = (reason) => {
    const firstPart =
      "Esta operação não pode ser realizada porque gera as seguintes inconsistências:\n";
    const [, second] = reason.split(firstPart);
    return { firstPart, secondPart: second };
  };
  const { firstPart, secondPart } = splitReasonPendency(reasonPendency);

  return (
    <div
      className={classes.pendencyCardContainer}
      data-testid="PendencyDetailsCard"
    >
      <div className={classes.titlePendencyDetailContainer}>
        <h1
          data-testid="TestTitleMovementDetail"
          className={classes.titlePendencyDetailText}
        >
          Detalhes da movimentação
        </h1>
      </div>
      <div className={classes.cardContainer}>
        <div className={classes.retiroAndPastureContainer}>
          <div className={classes.retiroText} data-testid="retreatName">
            Retiro: {originRetreatName}
          </div>
          <div className={classes.divider} />
          <div className={classes.pastureText} data-testid="pastureName">
            Pasto: {originPastureName}
          </div>
        </div>
        <div className={classes.explanationPendencyContainer}>
          <p>
            <span style={{ color: "black" }}>{firstPart}</span>
            <span style={{ color: "red" }}>{secondPart}</span>
          </p>
        </div>
        <div className={classes.content}>
          <div className={classes.fieldLine}>
            <CustomTextField
              classes={classes}
              testId={`creationUser`}
              id="creationUser"
              label="Usuário"
              value={creationUserName}
              flex={2}
            />
            <CustomTextField
              classes={classes}
              testId={`movementType`}
              id="movementType"
              label="Tipo de operação"
              value={typeMovement}
            />
          </div>
          <div className={classes.fieldLine}>
            <CustomTextField
              classes={classes}
              testId={`TotalHerd`}
              id="number"
              label="Total rebanho"
              value={countOrigin}
              flex={2}
            />
            <CustomTextField
              classes={classes}
              testId={`numberTotalMoved`}
              id="number"
              label="Total movimentado"
              value={number}
            />
          </div>
          <div className={classes.fieldLine}>
            <CustomTextField
              classes={classes}
              testId={`originCategoryName`}
              id="originCategoryName"
              label="Categoria inicial"
              value={originCategoryName}
              flex={2}
            />
            <CustomTextField
              classes={classes}
              testId={`destinyCategoryName`}
              id="destinyCategoryName"
              label="Categoria final"
              value={destinyCategoryName}
              flex={2}
            />
          </div>
          <div className={classes.fieldLine}>
            <CustomTextField
              classes={classes}
              testId={`originPastureName`}
              id="originPastureName"
              label="Pasto origem"
              value={originPastureName}
              flex={2}
            />
            <CustomTextField
              classes={classes}
              testId={`destinyPastureName`}
              id="destinyPastureName"
              label="Pasto destino"
              value={destinyPastureName}
            />
          </div>
          <div className={classes.fieldLine}>
            <CustomTextField
              classes={classes}
              testId={`operationDate`}
              id="operationDate"
              label="Data/hora da coleta"
              value={dateOperation}
              flex={2}
            />
            <CustomTextField
              classes={classes}
              testId={`createdAt`}
              id="createdAt"
              label={
                <div className={classes.labelContent}>
                  {isLateSync && (
                    <Tooltip
                      data-testid="testLateSyncTooltip"
                      title={CONTENT_TOOLTIP}
                      className={classes.tooltip}
                      classes={{
                        tooltip: classes.tooltipContent,
                      }}
                    >
                      <InfoIcon />
                    </Tooltip>
                  )}
                  Data/hora da sincronização
                </div>
              }
              value={creationDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = (theme) => ({
  pendencyCardContainer: {
    marginTop: "40px",
  },
  cardContainer: {
    borderRadius: "8px",
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)",
    border: "1px solid #e5e5ea",
    backgroundColor: "#fff",
  },
  retiroAndPastureContainer: {
    padding: "9px",
    margin: "32px",
    borderRadius: "8px",
    backgroundColor: "#F8F8F8",
    display: "flex",
    justifyContent: "flex-start",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "700",
    letterSpacing: "0.5px",
    color: "#444",
    textTransform: "uppercase",
    alignItems: "center",
  },
  divider: {
    width: "1px",
    height: "9px",
    backgroundColor: "#E2E2E2",
    margin: "5px 15px",
  },
  retiroText: {
    paddingLeft: "16px",
  },
  pastureText: {
    paddingLeft: "3px",
  },
  explanationPendencyContainer: {
    fontFamily: "Roboto",
    margin: "16px 32px",
    fontSize: "12px",
    fontStyle: "italic",
  },
  content: {
    margin: "32px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  fieldLine: {
    display: "flex",
    flexDirection: "row",
    gap: "32px",
  },
  titlePendencyDetailText: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#444",
  },
  labelContent: {
    display: "flex",
    alignItems: "center",
  },
  tooltip: {
    fontSize: "14.5px",
    color: "#FFC944",
    marginRight: "6px",
  },
  tooltipContent: {
    fontSize: "12px",
  },
  totalMoved: {
    width: "48.5%",
  },
});
export default withStyles(styles)(PendencyDetailsCard);
