import React, { useCallback } from "react";
import PendencyListViewPage from "./pendencyListViewPage";
import { connect } from "react-redux";
import dayjs from "dayjs";
import pendencyService from "features/pendency/pendencyService";

const PendencyListViewContainer = ({ farmId, history }) => {
  const serviceSourceTable = useCallback(
    async (params) => {
      const page = params.offset / params.limit + 1;
      const size = params.limit;

      const baseDate = dayjs().subtract(7, "days").toDate();

      const result = await pendencyService.getPendenciesByFarmId(
        page,
        size,
        farmId,
        baseDate
      );
      if (result && result.data && result.data.data) {
        return {
          total: result.data.data.total || 0,
          itens: result.data.data.enrichedPendencies || [],
        };
      }
      return {
        total: 0,
        itens: [],
      };
    },
    [farmId]
  );

  return (
    <PendencyListViewPage
      serviceSourceTable={serviceSourceTable}
      history={history}
    />
  );
};

const mapStateToProps = ({ fazenda }) => ({
  farmId: fazenda.FazendaSelecionada,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendencyListViewContainer);
