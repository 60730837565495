import React from "react";
import { Route, Switch } from "react-router-dom";
import PendencyListViewContainer from "./pendencyListView/pendencyListViewContainer";
import PendencyDetailsContainer from "./pendencyDetailsView/pendencyDetailsContainer";
import ContainerMovements from "features/shared/components/ContainerMovements";

const PendencyPage = ({ history, match }) => {
  return (
    <ContainerMovements history={history} match={match}>
      <Switch>
        <Route
          key="pendencyList"
          exact
          path={`/movimentacoesPendentes`}
          component={PendencyListViewContainer}
        />
        <Route
          key="pendencyDetails"
          exact
          path={`/movimentacoesPendentes/detalhes/:movementGrouper`}
          component={PendencyDetailsContainer}
        />
      </Switch>
    </ContainerMovements>
  );
};

export default PendencyPage;
